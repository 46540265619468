/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-nested-ternary */

import { useCallback, FC } from 'react';
import Typography from '@mui/material/Typography';

import { fileLoader } from '../../utils/file-loader';
import { SurveyContext, useSurveyContextSelector } from '../../survey-context';
import { SurveyBoxGenericFooterBtnWidth } from './survey-box-generic-footer';
import { CenterBox } from '../../utils/center-box';
import { PPButton } from '../pp-button';
import { useAppTranslation } from '../../../i18n';

export function handleNextButtonClick({
  asCampaignPreview,
  services,
  container,
  setState,
}: {
  asCampaignPreview: SurveyContext['args']['asCampaignPreview'];
  services: SurveyContext['args']['services'];
  container: SurveyContext['state']['container'];
  setState: SurveyContext['setState'];
}): Promise<void> {
  if (asCampaignPreview === 'preview-question') {
    return Promise.resolve();
  }

  if (container) {
    const surveyControlWrapper = container.querySelector('[x-name="surveyControlWrapper"]');
    if (surveyControlWrapper) {
      surveyControlWrapper.scrollTop = 0;
    }

    document.body.style.overflow = 'initial';
    const coordinates = container.getBoundingClientRect();
    window.scrollTo(coordinates.x, coordinates.y + window.scrollY - 65);

  }

  setState({ submitAnswerInProgress: true });
  const promise = services.submitAnswer().finally(() => setState({ submitAnswerInProgress: false }));

  return promise;
}

export const NextButton: FC = () => {
  const { i18n, t } = useAppTranslation('survey-tool');
  // too many props taken from context, no point in optimizing here
  const ctx = useSurveyContextSelector((c) => c as any) as SurveyContext;
  const { asCampaignPreview, asWidget, campaign, currentAnswer, height250, services } = ctx.args;
  const { container, submitAnswerInProgress } = ctx.state;
  const { setState } = ctx;

  const submitButtonDisabled = currentAnswer == null;
  const buttonText = asWidget && campaign.invitation.questionsLength === 1 ? t('submit', 'Submit') : t('next', 'Next');

  const getBtnInsides = (label: string) =>
    !height250 ? label : <img src={fileLoader(require('../../assets/images/next_oval_btn.svg'))} alt={label} />;

  const nextOnClick = useCallback(
    () => handleNextButtonClick({ asCampaignPreview, services, container, setState }),
    [asCampaignPreview, container, services, setState]
  );

  const backOnClick = useCallback(() => {
    setState({ submitAnswerInProgress: true });
    return services.handleBackButtonClick!().finally(() => setState({ submitAnswerInProgress: false }));
  }, [services.handleBackButtonClick, setState]);

  const restartOnClick = useCallback(() => window.location.reload(), []);

  if (asCampaignPreview === 'preview-question') {
    return null;
  }

  const nextBtn = (
    <PPButton
      x-name="surveyBoxFooterButton"
      loading={submitAnswerInProgress}
      disabled={submitButtonDisabled}
      onClick={nextOnClick}
      sx={{ width: '100%' }}
    >
      {getBtnInsides(buttonText)}
    </PPButton>
  );

  if (!asCampaignPreview) {
    return (
      <CenterBox
        sx={{
          maxWidth: SurveyBoxGenericFooterBtnWidth,
          marginTop: '0.5em',
          marginBottom: '0.5em',
        }}
      >
        {nextBtn}
      </CenterBox>
    );
  }

  return (
    <CenterBox
      sx={{
        flexDirection: 'column',
        '> *': {
          marginTop: '0.25em',
          marginBottom: '0.25em',
        },
      }}
    >
      <CenterBox sx={{ maxWidth: SurveyBoxGenericFooterBtnWidth }}>{nextBtn}</CenterBox>
      <CenterBox sx={{ width: '100%', maxWidth: SurveyBoxGenericFooterBtnWidth }}>
        <Typography variant="h5" color="wbBtnColor.contrastText">
          Preview only
          {i18n.language !== 'en' ? ` (language=${i18n.language})` : null}:
        </Typography>
      </CenterBox>
      <CenterBox sx={{ maxWidth: SurveyBoxGenericFooterBtnWidth }}>
        <PPButton
          x-name="surveyBoxFooterButtonBack"
          loading={submitAnswerInProgress}
          onClick={backOnClick}
          sx={{ width: '100%' }}
        >
          {getBtnInsides('Back')}
        </PPButton>
      </CenterBox>
      <CenterBox sx={{ maxWidth: SurveyBoxGenericFooterBtnWidth }}>
        <PPButton
          x-name="surveyBoxFooterButtonRestart"
          loading={submitAnswerInProgress}
          onClick={restartOnClick}
          sx={{ width: '100%' }}
        >
          {getBtnInsides('Restart')}
        </PPButton>
      </CenterBox>
    </CenterBox>
  );
};
